define(['app', '$console'], function(app, $console) {
  
  var _productsLoader = {
    /**
     * Module Constructor
     * @private
     */
    _init: function() {
      app.subscribe('products/update', function(stringTemplateResponse, productsWrapper) {
        _productsLoader._render(stringTemplateResponse, productsWrapper);
        app.publish('products/fixHeight');
      });

      app.subscribe('products/updateListTitle', function(stringTemplateResponse, listTitleWrapper) {
        _productsLoader._renderListTitle(stringTemplateResponse, listTitleWrapper);
      });

      app.subscribe('products/bind', function(target, event, callback, productsWrapper) {
        _productsLoader._bind(target, event, callback, productsWrapper);
      });
    },

    _cachedWrapper: {},

    /**
     * Render the products
     * @param {string} products
     * @param {object} productsWrapper
     * @private
     */
    _render: function(products, productsWrapper) {
      _productsLoader._cachedWrapper = productsWrapper;
      productsWrapper.innerHTML = products;
    },

    /**
     * Render the updated list title
     * @param {string} listTitle
     * @param {object} listTitleWrapper
     * @private
     */
    _renderListTitle: function(listTitle, listTitleWrapper) {
      listTitleWrapper.innerHTML = listTitle;
    },

    /**
     *
     * @param {string} target
     * @param {string} event
     * @param {function} callback
     * @param {object} [productsWrapper]
     * @private
     */
    _bind: function(target, event, callback, productsWrapper) {
      productsWrapper = productsWrapper || _productsLoader._cachedWrapper;
      if (!productsWrapper || !productsWrapper.querySelectorAll) {
        $console.warn('Could not find products wrapper', 'Products wrapper is' + typeof productsWrapper, '_bind');
        return;
      }
      var products = productsWrapper.querySelectorAll(target);
      app.select(products).on(event, callback);
    }
  };

  // Execute module constructor
  _productsLoader._init();

  // Unit testing access
  return _productsLoader;
});
